<template>
    <div class="shopping-all m-scroll">
        <HeaderBar title="Shopping cart">
            <span slot="right" @click="editCart" class="editCart" v-if="cart.length > 0">
                {{isEdit ? 'Done' : 'Edit'}}
            </span>
        </HeaderBar>
        <div class="shopping-main" @scroll="handleScroll($event)">
             <div class="gift-richmond" v-if="giftJson">
                 <img class="dp" src="@/assets/lottery/dp-min.png" alt="">
               <div class="flex">
                    <div class="gift-goods">
                        <img :src="giftJson.pic" alt="">
                    </div>
                    <div class="gift-info">
                        <p class="van-multi-ellipsis--l2">{{giftJson.title_en}}</p>
                        <p>Valid period: {{giftJson.start_date}} - {{giftJson.end_date}}</p>
                    </div>
                    <div class="gift-rule">x1</div>
               </div>
            </div>
            <div class="store-item m-padding" v-for="(item, index) in cart" :key="item.id + 'p' + item.is_presale">
                <div class="store-title van-hairline--bottom" :class="{'select-active' : select[index].select == true}" @click="selectAll(index)">
                    <img src="@/assets/cart/xuanzhe_1@2x.png" alt="">
                    <img src="@/assets/cart/xuanzhe_2@2x.png" alt="">
                    {{item.shop.title}}
                    <span class="m-mooby" style="width:55px" v-show="item.is_presale == 1">Pre-Sale</span>
                    <span>({{item.shop.cart.length}} Items)</span>
                </div>
                <div class="shipping">
                    <div class="shipping-left">
                        <p>
                            <img src="@/assets/cart/tishi@2x.png" alt="">
                            <span @click="$router.push('/en/city')">{{address.ename || 'select city'}}</span>
                            <span v-if="item.shop.freight_template && item.shop.freight_template.free_shipping == 1 && item.is_presale == 0">
                                Free shipping for orders over ${{item.shop.freight_template.money}}, needs $
                                <countTo
                                :decimals='2'
                                :startVal='0'
                                :endVal='(item.shop.freight_template.money - select[index].selectPrice) >= 0 ? item.shop.freight_template.money - select[index].selectPrice : 0'
                                :duration='1500' />
                                <!-- {{(item.shop.freight_template.money - select[index].selectPrice) >= 0 ? (item.shop.freight_template.money - select[index].selectPrice).toFixed(2) : '0.00'}} -->
                                more.
                            </span>
                            <span v-if="item.shop.freight_template && item.shop.freight_template.free_shipping == 1 && item.is_presale == 1">
                                Free shipping for orders over ${{item.shop.freight_template.presale_money}}, needs $
                                <countTo
                                :decimals='2'
                                :startVal='0'
                                :endVal='(item.shop.freight_template.presale_money - select[index].selectPrice) >= 0 ? item.shop.freight_template.presale_money - select[index].selectPrice : 0'
                                :duration='1500' />
                                <!-- {{(item.shop.freight_template.presale_money - select[index].selectPrice) >= 0 ? (item.shop.freight_template.presale_money - select[index].selectPrice).toFixed(2) : '0.00'}} -->
                                more.
                            </span>
                            <span  v-if="(!item.shop.freight_template || item.shop.freight_template.free_shipping == 0) && address">
                                There is no package discount in this area. Click the address to switch cities
                            </span>
                            <span v-if="!address">Check out free shipping rule for your location</span>
                        </p>
                        <div class="progress-bar" v-if="item.shop.freight_template && item.shop.freight_template.free_shipping == 1 && item.is_presale == 0">
                            <div class="progress-main">
                                <div class="progress"
                                :style="{'width': (select[index].selectPrice / item.shop.freight_template.money > 1 ? 1 : select[index].selectPrice / item.shop.freight_template.money) * 100 + '%'}"></div>
                            </div>
                            
                            <span>
                                <countTo
                                :startVal='0'
                                :endVal='(select[index].selectPrice / item.shop.freight_template.money > 1 ? 1 : select[index].selectPrice / item.shop.freight_template.money).toFixed(4) * 100'
                                :duration='1500' />
                                <!-- {{(select[index].selectPrice / item.shop.freight_template.money > 1 ? 1 : select[index].selectPrice / item.shop.freight_template.money).toFixed(4) * 100}} -->
                                %
                            </span>
                        </div>
                        <div class="progress-bar" v-if="item.shop.freight_template && item.shop.freight_template.free_shipping == 1 && item.is_presale == 1">
                            <div class="progress-main">
                                <div class="progress"
                                :style="{'width': (select[index].selectPrice / item.shop.freight_template.presale_money > 1 ? 1 : select[index].selectPrice / item.shop.freight_template.presale_money) * 100 + '%'}"></div>
                            </div>
                            <span>
                                <countTo
                                :startVal='0'
                                :endVal='(select[index].selectPrice / item.shop.freight_template.presale_money > 1 ? 1 : select[index].selectPrice / item.shop.freight_template.presale_money).toFixed(4) * 100'
                                :duration='1500' />
                                <!-- {{(select[index].selectPrice / item.shop.freight_template.presale_money > 1 ? 1 : select[index].selectPrice / item.shop.freight_template.presale_money).toFixed(4) * 100}} -->
                                %
                            </span>
                        </div>
                    </div>
                    <div class="shipping-right" v-if="item.shop.freight_template && item.shop.freight_template.free_shipping == 1" @click="goBuy(item)">
                        continue<br />shopping
                        <img src="@/assets/cart/icon-choudan@2x.png" alt="">
                    </div>
                </div>

                <!-- 预计到仓 -->
                <div class="pre-tip" v-if="item.is_presale == 1">
                    <p>Delivery instructions:</p>
                    <p>
                        Estimated time of arrival at warehouse in {{batch.purchase_period + batch.order_period + batch.delivery_date}}  business days. 
                    </p>
                </div>

                <div class="goods-list">
                    <van-swipe-cell v-for="(it, ind) in item.shop.cart" :key="it.goods_id + ind" right-width="145px">
                        <div class="goods-item"  :class="{'goods-active' : select[index].goods[ind].select == true}"
                        @click="goDetail(item, it)">
                            <div @click.stop="selectHandle(index, ind)">
                                <img src="@/assets/cart/xuanzhe_1@2x.png" alt="" class="goods-select">
                                <img src="@/assets/cart/xuanzhe_2@2x.png" alt="" class="goods-select">
                            </div>
                            <div class="haggle" v-if="it.bargain_user_goods_sku_id != 0 || it.live_user_join_id != 0">Bargain</div>
                            <img v-if="it.goods_sku.image" :src="it.goods_sku.image.remote_url"  alt="" class="goods-img">
                            <div class="goods-right">
                                <p class="van-multi-ellipsis--l2"> {{it.goods_info.name}}</p>
                                <div class="sku" v-if="it.bargain_user_goods_sku_id != 0 || it.live_user_join_id != 0">
                                    {{it.goods_sku.attr_symbol_string_en.split(',').join(' | ')}}
                                </div>
                                <div class="sku" v-else @click.stop="handleCartNoAnimat(it.goods_id, it.goods_sku.barcode, it.number, ind, it.id)">
                                    {{it.goods_sku.attr_symbol_string_en.split(',').join(' | ')}}
                                    <img src="@/assets/cart/gengduo@2x.png" alt="">
                                </div>
                                
                                <div class="total" @click.stop="">
                                    <span>${{(it.goods_sku.price - 0).toFixed(2)}}</span>
                                    <van-stepper 
                                        v-if="it.live_user_join_id == 0"
                                        v-model="it.number" 
                                        disable-input integer  
                                        @plus="changeNumber(1,it, index)" 
                                        @minus="changeNumber(2,it, index)" 
                                        :max="(it.bargain_user_goods_sku_id == 0 && !item.is_presale) ? it.goods_sku.sku_stock.stock : (it.bargain_user_goods_sku_id == 0 && item.is_presale)? 5 : it.bargain_user_goods_sku.bargain_goods_sku.buy_limit" 
                                        class="step-total" />
                                </div>
                            </div>
                        </div>
                        <template #right>
                            <img src="@/assets/cart/icon_like@2x.png"   alt="like" class="like-img-but" @click="addFavoriteHandle(it.goods_id)">
                            <img src="@/assets/cart/icon_delete@2x.png" alt="like" class="like-img-but" @click="delCartHandle(it.id)">
                        </template>
                    </van-swipe-cell>
                </div>
            </div>
            
            <div v-if="invalid_sale.length != 0">
                <!-- <div class="invalid-title">无效商品</div> -->
                <div class="invalid m-padding">
                    <div class="mask"></div>
                    <div v-for="(item,index) in invalid_sale" :key="index" class="invalid_goods">
                        <div class="">
                            <img v-if="item.goods_sku.image" :src="item.goods_sku.image.remote_url" alt="">
                            <p class="van-multi-ellipsis--l2">{{item.goods_info.name}}</p>
                        </div>
                    </div>
                </div>
                <div class="clear">
                    <span @click="delInvalid">
                        Clear invalid items
                    </span>
                </div>
            </div>

            <!-- 缺省图 -->
            <img class="cart-img" v-if="cart.length <= 0" src="@/assets/cart/cart_empty.png" alt="">
            <p class="cart-no" v-if="cart.length <= 0">
                "The shopping cart is so empty, goes to fill it up ~"
            </p>

            <!-- 为您推荐 -->
            <m-divider title="Recommended" class="m-cat-devider" v-if="!isEdit"></m-divider>
            <goods-columns @cartClick="handleCart" :data="remGoodsList" class="m-padding" v-if="!isEdit"></goods-columns>

            <van-loading v-show="loading" type="spinner" color="#ED2A24" size="6.4vw" v-if="!isEdit"></van-loading>
        </div>
        <div class="cart-tools noselect van-hairline--top" :class="{'edit-cart': isEdit, 'cart-tools-delay' : !isEdit}">
            <div class="price">Total：${{ Math.abs(selectTotal).toFixed(2) }}</div>
            <div class="calc-order" @click="orderSubmit">Submit</div>
        </div>
        <div class="cart-tools cart-tools-del noselect van-hairline--top" :class="{'edit-cart-delete': isEdit, 'cart-tools-delay' : isEdit}">
            <div class="tools-all" :class="{'select-active' : isSelectAll}" @click="selectAllStore">
                <img src="@/assets/cart/xuanzhe_1@2x.png" alt="">
                <img src="@/assets/cart/xuanzhe_2@2x.png" alt="">
                All
            </div>
            <!-- <div class="calc-order favorite" @click="calcOrder">Move to favorite</div> -->
            <div class="calc-order" @click="delCartHandle">Delete</div>
        </div>

        <!-- 选择SKU 加入购物车 -->
        <m-sku :title="skuTitle" :show="skuModal" @close="val => skuModal=val" @success="handleCartSuccess" :goodsId.sync='goodsId'
        :barcode.sync="goodsBarCode" :num.sync="goodsNum" @edit="changeSku"></m-sku>

        <!-- 加入购物车动画 -->
        <m-animate :start="start" :dom="start_dom" @end="handleCartEnd"></m-animate>

        <!-- <FooterBar /> -->
    </div>
</template>

<script>
import HeaderBar from '@/components/en/m-header.vue'
import MSku         from '@/components/en/m-sku.vue'
import MAnimate     from '@/components/en/cart-animate.vue'
import GoodsColumns from '@/components/en/goods-columns.vue'
import MDivider     from '@/components/en/m-divider.vue'
import { getBatch } from '@/api/public.js'
import {  is_gift } from '@/api/zh/index.js'
import { add } from '@/untils/js/common.js'
import countTo from 'vue-count-to'
import { getCartRemGoodsList } from '@/api/en/cart.js'
import '@/untils/js/geocoder.js'
import { getCart, subCart, updateCart, delCart, updateSku, del_invalid } from '@/api/zh/cart.js'
import { favoriteGoods } from '@/api/en/favorite.js'
export default {
    components: { HeaderBar, MSku, MAnimate, GoodsColumns, MDivider, countTo },
    data() {
        return {
            giftJson    : '',
            value       : '1',
            invalid_sale: [],   // 无效商品
            normal_sale : [],   // 正常商品
            pre_sale    : [],   // 预售商品
            cart        : [],
            select      : [],
            isEdit      : false,
            editSelect  : [],  // 购物车 编辑时 选择状态存放
            remGoodsList: [],
            start       : false,      // 是否开启加入购物车动画
            start_dom   : null,   // 开启动画的起点DOM 
            skuModal    : false,    // 控制显示 选择sku加入购物车面板
            loading     : false,
            islock      : false,
            page        : 1,
            startAnimat : false, // 是否开启加入购物车动画
            isSelectAll : false,
            noMore      : false,
            address     : '',
            goodsId     : '',
            nowAddress  : '', // 定位地址
            goodsBarCode: '',
            goodsNum    : 1,
            skuTitle    : 'Add to Cart',
            skuIndex    : 0,
            cartId      : '',
            allTotal    : 0,
            selectTotal : 0,
            // isAllSelect : false,
            isAddCart   : false,
            batch: {}
        }
    },
    methods: {
        // 清空无效
        delInvalid() {
            del_invalid().then(res => {
                if(res) {
                    this.getCartHandle()
                    this.$notify({ type: 'success', message: 'success' })
                }
            })
        },
        Gift() {
            is_gift().then(res => {  
                if(res){
                    this.giftJson = res.data;
                }
            })
        },
        // 内容滑动事件
        handleScroll(e){
            if(e.target.scrollHeight - e.target.clientHeight- e.target.scrollTop <= 50){
                if(!this.islock){
                    e.preventDefault()
                    e.stopPropagation()
                    this.loading = true
                    this.page++
                    this.getCartRemGoodsListHandle()
                }
            }
        },
        calcOrder(e) {
            // 提交订单
            e.target.classList.add('calc-order-scale')
            setTimeout(() => {
                e.target.classList.remove('calc-order-scale')
            }, 150)

            this.$router.push('/en/payment')
        },
        editCart() {
            // 编辑
            this.isEdit = !this.isEdit
        },
        selectHandle(index, ind) {
            if(this.select[index].goods[ind].buy_status != 0) {
                // 选择规格
                this.select[index].goods[ind].select = !this.select[index].goods[ind].select
                this.selectTotal = add(this.selectTotal, (this.select[index].goods[ind].select ? 1 : -1) * this.select[index].goods[ind].goods_sku.price * this.select[index].goods[ind].number) 
                
                // 循环遍历  如果该index下全部选中则 全部选中按钮  全部选中
                let allSelect = true
                this.select[index].selectPrice = 0
                this.select[index].goods.forEach(el => {
                    if(!el.select) {
                    allSelect = false 
                    } else {
                        this.select[index].selectPrice += el.goods_sku.price * el.number
                    }
                })
                if(!allSelect) {
                    this.select[index].select = false
                }else{
                    this.select[index].select = true
                }
            }
        },
        selectAll(index) {
            // 选择全部
            this.select[index].selectPrice = 0
            if(!this.select[index].select) {
                this.select[index].select = true
                // this.select[index].selectPrice = this.cart[index].total_price
                this.select[index].goods.forEach((el, ind) => {
                    if(el.buy_status != 0) {
                        this.selectTotal = add(this.selectTotal, (el.select ? 0 : 1) * this.select[index].goods[ind].goods_sku.price * this.select[index].goods[ind].number)
                        this.select[index].selectPrice += this.select[index].goods[ind].goods_sku.price* this.select[index].goods[ind].number
                        el.select = true
                    }
                })
                // this.isSelectAll = true
            }else{
                this.select[index].select = false
                this.select[index].selectPrice = 0
                this.select[index].goods.forEach((el, ind) => {
                    this.selectTotal = add(this.selectTotal, (el.select ? -1 : 0) * this.select[index].goods[ind].goods_sku.price * this.select[index].goods[ind].number)
                    el.select = false
                })
                // this.isSelectAll = false
            }
        },
        selectAllStore() {
            // 全部商家全选
            this.selectTotal = 0
            this.isSelectAll = !this.isSelectAll
            this.select.forEach(el => {
                el.select = this.isSelectAll
                el.goods.forEach(ll => {
                    ll.select = this.isSelectAll
                })
            })
            if(this.isSelectAll) {
                this.select.forEach(el => {
                    this.selectTotal += el.selectPrice
                })
            } else {
                this.selectTotal = 0
            }
        },
        goDetail(item, it) {
            // 判断跳转bargain_user_goods_sku_id 是否为0  为0跳转正常商品详情  反之跳转砍价详情
            if(it.bargain_user_goods_sku_id == 0  && it.live_user_join_id == 0) {
                this.$router.push({path:`/en/goods/detail/${it.goods_id}`, query: {pre: item.is_presale == 1 ? 1 : 0}})
            } else if(it.live_user_join_id != 0) {
                this.$router.push({path:`/liveDetail/${it.goods_id}`,query:{haggle_id:it.live_user_join_id}})
            } else {
                this.$router.push({path:`/en/haggleDetail/${it.goods_id}`,query:{haggle_id:it.bargain_user_goods_sku_id}})
            }
        },
        calcPrice() {
            // 计算价格

        },
        handleCart(goods, e){
            // 商品列表购物车点击事件
            this.skuTitle    = 'Add to Cart'
            this.goodsId     = goods
            this.skuModal    = true
            this.start_dom   = e 
            this.startAnimat = true
        },
        handleCartNoAnimat(goods, barcode, num, index, id) {
            // 加入购物车  无动画
            this.skuTitle     = 'Confirm'
            this.goodsBarCode = barcode
            this.goodsNum     = num
            this.goodsId      = goods
            this.skuIndex     = index
            this.cartId       = id
            this.skuModal     = true
            this.startAnimat  = false
            this.isAddCart    = false
        },
        handleCartSuccess(){
            // sku 弹窗加入购物车成功回调
            this.skuModal = false
            console.log(this.startAnimat)
            if(this.startAnimat) {
                let timer = setTimeout( () => {
                    this.start = true

                    // 重新获取购物车
                    this.getCartHandle()

                    clearTimeout(timer)
                },300)
            }        
        },
        handleCartEnd(){
            // 加入购物车动画结束
            this.start = false
        },
        getCartRemGoodsListHandle() {
            // 获取自定义为你推荐
            this.islock  = true
            this.loading = true
            getCartRemGoodsList({page: this.page}).then(res => {
                this.loading  = false
                if(res) {
                this.remGoodsList = this.remGoodsList.concat(res.data.data)
                this.islock   = false
                }
            })
        },
        getCartHandle() {
            // 获取购物车数据
            this.$store.commit('load')
            getCart({city: this.address.ename,province:this.address.pname}).then(res => {
                if(res.code == 20000) {
                    this.invalid_sale = res.data.invalid_sale
                    this.normal_sale = res.data.normal_sale
                    this.normal_sale.forEach(item =>{
                        item.is_presale = 0
                    })
                    this.pre_sale = res.data.pre_sale
                    this.pre_sale.forEach(item =>{
                        item.is_presale = 1
                    })
                    this.cart = [ ...this.normal_sale, ...this.pre_sale]
                    this.selectTotal = 0
                    this.select = []
                    this.cart.forEach(el => {
                        el.shop.cart.forEach( ele => {
                            this.$set(ele,'select',false)
                        })
                        this.select.push({storeId: el.shop.id, select: false, goods: el.shop.cart, selectPrice: 0})
                    })
                } else {
                    this.allTotal = 0
                }
            }).catch(() => {
                this.cart = []
            }).finally(() => {
                this.$store.commit('unload')
            })
        },
        // 添加喜欢列表
        addFavoriteHandle(id) {
            favoriteGoods(id, {status: 1}).then(res => {
                if(res) {
                    this.$notify({ type: 'success', message: res.msg})
                    this.getCartHandle()
                }
            }).catch(() => {
                this.$notify({ type: 'warning', message: 'Already exists'})
            })
        },
        // 更改购物车数量
        changeNumber(type,it, index) {
            let num = 0
            if(type == 1) {
                num = it.number + 1
            }else {
                num = it.number - 1
            }
            updateCart(it.id, {buy_number: num}).then(res => {
                if(res) {
                    this.getCartHandle()
                    if(type == 1) {
                        this.$store.commit('addCart')
                    }else {
                       this.$store.commit('delCart')
                    }

                    if(it.select) {
                        this.select[index].selectPrice += (type == 1 ? 1 : -1) * it.price 

                        this.selectTotal += (type == 1 ? 1 : -1) * it.price
                    }
                    
                    // this.select[index].goods.forEach( (el,ind) => {
                    //     if(el.select) {
                    //         this.selectTotal += (type == 1 ? 1 : -1) * this.select[index].goods[ind].price 
                    //     }
                    // })
                    this.$notify({ type: 'success', message: res.msg, })
                }else {
                    if(type == 1) {
                        it.number --
                    }else {
                        it.number ++
                    }
                }
            })
        },
        changeSku(sku) {
            updateSku(this.cartId, {barcode: sku.barcode}).then(res => {
                if(res) {
                    this.$notify({ type: 'success', message: res.msg, })
                    this.getCartHandle()
                    this.skuModal = false
                }else{
                    this.$notify({ type: 'warning', message: res.msg, })
                }
            })
        },
        delCartHandle(e) {
            // 删除购物车
            let arr = []
            if(e.target) {
                e.target.classList.add('calc-order-scale')
                setTimeout(() => {
                    e.target.classList.remove('calc-order-scale')
                }, 150)
                this.select.forEach(e => {
                    e.goods.forEach(el => {
                        if(el.select) {
                            arr.push(el.id)
                        }
                    })
                })
            } else {
                arr.push(e)
            }
            if(arr.length == '') {
                this.$notify({ type: 'warning', message: 'Please make selection' })
                return false
            }
            let params = {
                ids: arr.join(',')
            }
            console.log(params)
            delCart(params).then(res => {
                if(res.code == 20000) {
                    this.$notify({ type: 'primary', message: res.msg })
                    this.getCartHandle()
                    this.isEdit = false

                    this.select.forEach(el => {
                        el.select = false
                        el.goods.forEach( ele => {
                            this.$set(ele, 'select', false)
                        })
                    })
                } else {
                    this.$notify({ type: 'warning', message: res.msg })
                }
            })
        },
        // 提交订单
        orderSubmit(e) {
            e.target.classList.add('calc-order-scale')
            setTimeout(() => {
                e.target.classList.remove('calc-order-scale')
            }, 150)

            let arr = []
            this.select.forEach(e => {
                e.goods.forEach(el => {
                    if(el.select) {
                        arr.push(el.id)
                    }
                })
            })
            if(arr.length == '') {
                this.$notify({ type: 'warning', message: 'Please make selection' })
                return false
            }
            let params = {
                ids: arr.join(',')
            }
            subCart(params).then(res => {
                if(res) {
                    this.$router.push('/en/payment')
                }
            })
        },
        getBatchHandle() {
            // 获取批次时间
            getBatch().then(res => {
                if(res) {
                    this.batch = res.data
                }
            })
        },
        goBuy(data) {
            // 去凑单
            if(data.is_presale == 1 && data.shop.id == 1) {
                // 预售模块
                this.$router.push(`/en/presale?id=6`)
            } else {
                // 跳转店铺
                this.$router.push(`/en/mooby?id=4`)
            }
        }
    },
    created() {
        // if (this.$store.state.isMiniProgram) {
        //     window.wx.miniProgram.navigateTo({ url: "/pages/map/map" })
        // }
        // 从本低存储中获取数据
        if(sessionStorage.getItem('geo_address')) {
            this.address = JSON.parse(sessionStorage.getItem('geo_address'))
        }
        this.Gift();
        this.getCartHandle()
        this.getCartRemGoodsListHandle()
        this.$store.dispatch('getCart')
        this.getBatchHandle()
    },
    mounted() {
        let timer = setInterval(() => {
            if(this.$store.state.city != null) {
                let city = this.$store.state.city
                if(city) {
                    if(!this.address) {
                        this.address = city
                        sessionStorage.setItem('geo_address', JSON.stringify(city))
                    }
                }
                clearInterval(timer)
            }
        }, 500)
    }
}
</script>

<style scoped lang="less">
@import './index.less';
</style>

<style lang="less">
.shopping-all{
    .step-total{width:80px;height:20px;margin-right:-8px;
        button,input{height:100%;border-radius: 0}
        input{border:1px solid rgba(230,230,230,1);background-color: #fff;margin:0}
        button{width:20px;background-color: rgba(230,230,230,1)}
    }
    .m-cat-padding{padding:0}
    .m-cat-devider{
        margin:15px 0;
        font-size:18px;
        font-weight:500;
        color:rgba(15,30,51,1);
    }
    .pre-tip{display: flex;border-top:1px solid #f7f7f7;border-bottom:1px solid #f7f7f7;padding: 10px 0;
        p{font-size: 12px;color:#888;line-height: 20px;
            &:nth-of-type(1){width: 110px;margin-right:10px}
        }
    }
    .cart-img{width:80%;margin:50px auto 20px auto;display:block;padding-left:5%}
    .cart-no{margin-bottom:100px;text-align:center;font-size:14px;color:#888}
    .invalid {
        border-radius: 8px;
        background: #fff;
        position: relative;
        .mask {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(231, 231, 231, 0.4);
            border-radius: 8px;
        }
        .invalid_goods {
            padding: 8px 0;
            >div {
                color: #666;
                display: flex;
                align-items: center;
                img {
                    width: 50px;
                    height: 50px;
                    margin-right: 15px;
                    flex-shrink:0;
                }
            }
        }
    }
    .invalid-title {
        text-align: center;
        color: #666;
        margin-bottom: 5px;
    }
    .clear {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        span {
            padding: 2px 10px;
            border: 1px solid #333;
            border-radius: 10px;
            font-size: 12px;
        }
    }
}
</style>
